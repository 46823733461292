import { toggleLoading } from './loading';
import { NotificationToaster } from "../utils/notificationToaster";
import { getTeamActiveChallanges, getLeaderboardDetails, getLeaderboardGraph} from '../services/leaderboard.service';

const getTeamActiveChallangesAction = () => (dispatch) => {
    dispatch(toggleLoading(true));

    return getTeamActiveChallanges()
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response.data.guides);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

const getLeaderboardDetailsAction = (param) => (dispatch) => {
    dispatch(toggleLoading(true));

    return getLeaderboardDetails(param)
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response?.data?.users);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

const getLeaderboardGraphAction = (param) => (dispatch) => {
    dispatch(toggleLoading(true));

    return getLeaderboardGraph(param)
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response?.data);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

export { getTeamActiveChallangesAction, getLeaderboardDetailsAction,getLeaderboardGraphAction };