import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CommentIcon from "@mui/icons-material/Comment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SendIcon from "@mui/icons-material/Send";
import VerifiedIcon from "../../assets/icons/dashboard/verified.png";
import {
	Box,
	Card,
	CardContent,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addNewCommentAction,
	addLikeAction,
	removeLikeAction,
	deleteCommentAction,
} from "../../actions/posts";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LinkifyTypography from "../../utils/linkifyTypography";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "10px",
	backgroundColor: "#000000",
	color: "#fff",
	minHeight: isMobile ? "auto" : "70px",
}));

const StyledTextField = styled(TextField)(() => ({
	"& label": {
		color: "white",
		fontFamily: "Inter",
		fontWeight: 400,
	},
	"& label.Mui-focused": {
		color: "white",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#313440",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#313440",
		},
		"&:hover fieldset": {
			borderColor: "#313440",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#313440",
		},
		backgroundColor: "#242526",
		color: "white",
		border: "1px solid #313440",
		"*::-webkit-scrollbar": {
			width: 0,
		},
		// height: '50px',
	},
	".MuiFormHelperText-root.Mui-error": {
		color: "red",
	},
}));

const FeedCard = ({ post, setLikesDialogListing, setLikesDialogOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const userId = useSelector((state) => state.auth.user.id);
	const isLiked = post?.likes?.filter((obj) => obj.user.id === userId);

	const [newCommentText, setNewCommentText] = useState("");
	const [showComments, setShowComments] = useState(false);

	const handleAddNewComment = () => {
		if (newCommentText !== "") {
			dispatch(addNewCommentAction(newCommentText, post.id))
				.then((res) => {
					// setAddComment(false);
					setNewCommentText("");
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleAddLike = () => {
		dispatch(addLikeAction(post.id))
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleRemoveLike = () => {
		dispatch(removeLikeAction(post.id, isLiked[0]?.id))
			.then((res) => {})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteComment = (commentId) => {
		dispatch(deleteCommentAction(post.id, commentId))
			.then((res) => {})
			.catch((err) => {
				console.log(err);
			});
	};

	const getDateTime = (dateTime) => {
		const today = new Date(dateTime);
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0");
		const yyyy = today.getFullYear();

		const hour = String(today.getHours()).padStart(2, "0");
		const minutes = String(today.getMinutes()).padStart(2, "0");

		return `${dd}/${mm}/${yyyy} ${hour}:${minutes}`;
	};

	return (
		<StyledCard isMobile={isMobile}>
			<CardContent>
				<Grid container spacing={1}>
					<Grid container item spacing={2} wrap="nowrap" alignItems={"center"}>
						<Grid item>
							{post?.user?.avatar_url ? (
								<LazyLoadImage
									src={post?.user?.avatar_url}
									alt=""
									style={{
										color: "#fff",
										width: "42px",
										height: "42px",
										borderRadius: "100px",
										objectFit: "cover",
									}}
									effect="blur"
								/>
							) : (
								<AccountCircleIcon
									sx={{ color: "#fff", width: "50px", height: "50px" }}
								/>
							)}
						</Grid>

						<Grid container item direction={"column"}>
							<Grid item>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between", // Add this to separate the name and points
										width: "100%", // Ensure the box spans the full width of the container
									}}
								>
									<Typography
										color="#ffff"
										style={{
											display: "flex",
											alignItems: "center",
											cursor: "pointer",
										}} // Flexbox to align the icon and text vertically
										fontFamily={"Roboto"}
										fontSize={"20px"}
										fontWeight={400}
										textTransform={"capitalize"}
										onClick={() => {
											if (!post?.user?.is_admin) {
												dispatch({
													type: "USER_DETAILS_SUCCESS",
													payload: post?.user,
												});
												navigate(`/userDetails/${post?.user?.id}`);
											}
										}}
									>
										{post?.user?.first_name} {post?.user?.last_name}
										{post?.user?.is_admin && (
											<img
												src={VerifiedIcon}
												alt={""}
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "5px",
													verticalAlign: "middle",
												}}
											/>
										)}
									</Typography>

									{post?.points != null && (
										<Typography
											color="#FF7A1E"
											fontFamily={"Roboto"}
											fontSize={"18px"}
											fontWeight={700}
											mt={-0.3}
											sx={{ marginLeft: "auto" }} // Add this to push the points to the right
										>
											+{post?.points}xi
										</Typography>
									)}
								</Box>
							</Grid>

							<Grid item>
								<Typography
									mt={0.4}
									paddingTop={"0px"}
									fontFamily={"Roboto"}
									fontSize={"12px"}
									fontWeight={400}
									color="#9F9F9F"
									textTransform={"capitalize"}
									display="inline"
								>
									{post?.created_at}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					{/* <Grid item>
                        <img src={StarIcon} alt={""} width={"24px"} height={"24px"} />
                    </Grid> */}

					<Grid item>
						<LinkifyTypography
							fontFamily={"Inter"}
							fontSize={"16px"}
							fontWeight={400}
							lineHeight={"26px"}
							color={"#e4e6eb"}
						>
							{post?.message}
						</LinkifyTypography>
					</Grid>

					{post?.attachment_url && (
						<Grid item xs={12} mt={1} mb={1}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{post?.post_type === "video" ? (
									<>
										<video
											style={{
												maxWidth: "100%",
												borderRadius: "10px",
											}}
											controls
											controlsList="nodownload"
										>
											<source src={post?.attachment_url} alt={""} />
										</video>
									</>
								) : (
									<>
										<LazyLoadImage
											src={post?.attachment_url}
											style={{
												maxWidth: "100%",
												borderRadius: "10px",
											}}
											effect="blur"
										/>
									</>
								)}
							</Box>
						</Grid>
					)}

					<Grid container item spacing={2} xs={12} mt={-1} wrap="nowrap">
						<Grid item>
							<Box
								sx={{
									display: "inline-flex",
									height: "30px",
									alignItems: "center",
								}}
							>
								<Typography
									style={{ cursor: "pointer" }}
									fontFamily={"Inter"}
									fontSize={"13px"}
									fontWeight={700}
									lineHeight={"19.5px"}
									display={"inline"}
									color={"#fff"}
									onClick={() => {
										setLikesDialogListing(post?.likes);
										setLikesDialogOpen(true);
									}}
								>
									{post?.likes_count} Likes
								</Typography>
							</Box>
						</Grid>

						<Grid item>
							<Box
								sx={{
									display: "inline-flex",
									height: "30px",
									alignItems: "center",
								}}
							>
								{/* <IconButton style={{ padding: 0, margin: 0 }} onClick={() => { setShowComments(true) }}>
                                    <CommentIcon style={{ marginRight: '7px', color: '#fff', width: '19px', height: '19px' }} />

                                    <Typography fontFamily={'Inter'} fontSize={'13px'} fontWeight={700} lineHeight={"19.5px"} display={"inline"} color={"#fff"}>
                                        {post?.comments_count} Comments
                                    </Typography>
                                </IconButton> */}

								<Typography
									style={{ cursor: "pointer" }}
									fontFamily={"Inter"}
									fontSize={"13px"}
									fontWeight={700}
									lineHeight={"19.5px"}
									display={"inline"}
									color={"#fff"}
									onClick={() => {
										setShowComments(!showComments);
									}}
								>
									{post?.comments_count} Comments
								</Typography>
							</Box>
						</Grid>
					</Grid>

					<Grid container item xs={12} alignItems={"center"}>
						<Grid item>
							<IconButton
								style={{ padding: 0, margin: 0 }}
								onClick={() => {
									isLiked?.length === 0 ? handleAddLike() : handleRemoveLike();
								}}
							>
								{isLiked?.length === 0 ? (
									<>
										<FavoriteBorderOutlinedIcon
											style={{
												marginRight: "15px",
												color: "#fff",
												width: "28px",
												height: "28px",
											}}
										/>
									</>
								) : (
									<>
										<FavoriteIcon
											style={{
												color: "#FF7A1E",
												marginRight: "15px",
												width: "28px",
												height: "28px",
											}}
										/>
									</>
								)}
							</IconButton>
						</Grid>

						<Grid item>
							<IconButton
								style={{ padding: 0, margin: 0 }}
								onClick={() => {
									setShowComments(!showComments);
								}}
							>
								<CommentIcon
									style={{
										marginRight: "7px",
										color: "#fff",
										width: "25px",
										height: "25px",
									}}
								/>
							</IconButton>
						</Grid>
					</Grid>

					{showComments && (
						<Grid
							item
							xs={12}
							mt={2}
							style={{ padding: 0 }}
							sx={{
								maxHeight: "400px",
								overflowY: post?.comments?.length === 0 ? "hidden" : "auto",
								"&::-webkit-scrollbar": {
									width: "0.4em",
									opacity: 0,
								},
								"&::-webkit-scrollbar-track": {
									backgroundColor: "rgba(0, 0, 0, 0.2)",
									borderRadius: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									borderRadius: "6px",
								},
								" &::-webkit-scrollbar:hover": {
									opacity: 1,
								},
							}}
						>
							<Grid container item spacing={0}>
								{post?.comments?.map((comment) => {
									return (
										<Grid item xs={12}>
											<StyledCard
												isMobile={isMobile}
												style={{ borderRadius: 0, boxShadow: "none" }}
											>
												<CardContent
													sx={{
														paddingBottom: 0,
														"&:last-child": { paddingBottom: 0 },
													}}
												>
													<Grid container alignItems={"start"} spacing={4}>
														<Grid item xs={isMobile ? 2 : 1}>
															{comment?.user?.avatar_url ? (
																<img
																	src={comment?.user?.avatar_url}
																	alt={""}
																	style={{
																		color: "#fff",
																		width: "42px",
																		height: "42px",
																		borderRadius: "100px",
																		verticalAlign: "middle",
																		objectFit: "cover",
																	}}
																/>
															) : (
																<AccountCircleIcon
																	sx={{
																		color: "#fff",
																		width: "40px",
																		height: "40px",
																	}}
																/>
															)}
														</Grid>

														<Grid item xs={10}>
															<Typography>
																{comment?.user?.first_name}{" "}
																{comment?.user?.last_name} &nbsp;
															</Typography>

															<LinkifyTypography fontSize={"14px"}>
																{comment?.message}
															</LinkifyTypography>

															{/* <br /> */}

															{comment?.user?.id === userId && (
																<IconButton
																	style={{ padding: 0, margin: 0 }}
																	onClick={() => {
																		handleDeleteComment(comment.id);
																	}}
																>
																	{/* <DeleteOutlinedIcon style={{ color: '#939BA3', height: '20px' }} /> */}
																	<Typography
																		fontSize={"13px"}
																		display="inline"
																		style={{ color: "#939BA3" }}
																	>
																		Delete
																	</Typography>
																</IconButton>
															)}
														</Grid>

														{/* <Grid item xs={12} style={{ paddingLeft: "15px" }}>
                                                                    {comment?.message}
                                                                </Grid> */}

														{/* <Grid item xs={isMobile ? 2.5 : 1.5}>
                                                                    <IconButton>
                                                                        <ChatBubbleOutlineOutlinedIcon style={{ color: '#939BA3', width: '15px' }} />
                                                                        <Typography fontSize={"13px"} display="inline" style={{ color: '#939BA3', marginLeft: '5px' }}>
                                                                            Reply
                                                                        </Typography>
                                                                    </IconButton>
                                                                </Grid> */}

														{/* <Grid item style={{ padding: 0 }}>
                                                                    <IconButton>
                                                                        <DeleteOutlinedIcon style={{ color: '#939BA3', height: '20px' }} />
                                                                        <Typography fontSize={"13px"} display="inline" style={{ color: '#939BA3', marginLeft: '3px', marginTop: '2px' }}>
                                                                            Delete
                                                                        </Typography>
                                                                    </IconButton>
                                                                </Grid> */}
													</Grid>
												</CardContent>
											</StyledCard>
										</Grid>
									);
								})}

								<Grid item xs={12}>
									<StyledTextField
										name="comment"
										placeholder="Write a Comment..."
										fullWidth
										value={newCommentText}
										onChange={(e) => {
											setNewCommentText(e.target.value);
										}}
										autoFocus
										// onBlur={() => setShowComments(false)}
										style={{ paddingTop: 10 }}
										multiline
										maxRows={4}
										InputProps={{
											style: {
												overflow: "auto",
											},
											endAdornment: (
												<>
													<InputAdornment position="end">
														<IconButton
															style={{ padding: 0 }}
															onClick={handleAddNewComment}
														>
															<SendIcon
																style={{
																	color: "#b2b2b2",
																	width: "20px",
																	height: "20px",
																}}
															/>
														</IconButton>
													</InputAdornment>
												</>
											),
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default FeedCard;
