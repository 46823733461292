import {
	Box,
	Card,
	CardContent,
	Chip,
	Grid,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import StarIcon from "../../../assets/icons/dashboard/star.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import BlurryPlaceHolder from "../../../assets/placeholderBlurry.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "transparent",
	color: "#fff",
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#FF802A",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const StyledChipBlack = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#000",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const ChallengeCardLeaderboard = ({ challenge }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const screenWidth = window.screen.width;

	const handleViewGuide = (challenge) => {
		dispatch({ type: "UPDATE_GUIDE", payload: challenge });
		navigate("/guideInfo");
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				cursor: "pointer",
				marginBottom: 2,
			}}
			onClick={() => handleViewGuide(challenge)}
		>
			<Grid container spacing={2}>
				<Grid item>
					<LazyLoadImage
						src={challenge?.thumbnail}
						placeholderSrc={BlurryPlaceHolder}
						alt=""
						width="100px"
						height="100px"
						style={{ borderRadius: "12px" }}
					/>
				</Grid>

				<Grid item xs={6}>
					<Typography
						fontFamily={"Roboto"}
						fontWeight={400}
						fontSize={"20px"}
						color={"#fff"}
						lineHeight={"24px"}
					>
						{challenge?.title}
					</Typography>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "6px",
							marginTop: "6px",
						}}
					>
						<StyledChip label={challenge?.guide_type} />

						{challenge?.themes?.length !== 0 &&
							challenge?.themes?.map((theme) => {
								return <StyledChipBlack label={theme} />;
							})}
					</Box>

					{screenWidth >= 390 && (
						<Box
							sx={{
								display: "flex",
								marginTop: "10px",
								alignItems: "center",
								gap: "5px",
							}}
						>
							{challenge?.users?.slice(0, 3)?.map((user, index) => {
								return (
									<>
										{user?.avatar_url ? (
											<LazyLoadImage
												src={user?.avatar_url}
												alt={""}
												style={{
													color: "#fff",
													width: isMobile ? "34px" : "42px",
													height: isMobile ? "34px" : "42px",
													borderRadius: "100px",
													objectFit: "cover",
												}}
												effect={"blur"}
											/>
										) : (
											<AccountCircleIcon
												sx={{
													color: "#fff",
													width: isMobile ? "40px" : "50px",
													height: isMobile ? "40px" : "50px",
												}}
											/>
										)}
									</>
								);
							})}
						</Box>
					)}
				</Grid>
			</Grid>

			<KeyboardArrowRightIcon style={{ height: "80px", width: "50px" }} />
		</Box>
	);
};

export default ChallengeCardLeaderboard;
