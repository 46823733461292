import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Card, CardContent, Chip, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StarIcon from '../../assets/icons/dashboard/star.svg';
import LockIcon from '@mui/icons-material/Lock';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlurryPlaceHolder from '../../assets/placeholderBlurry.png';
import BlurryChapterPlaceHolder from '../../assets/placeholderChapterBlurry.png';

const StyledCard = styled(Card)(({ isMobile }) => ({
    borderRadius: '8px',
    backgroundColor: '#FFFFFF1A',
    color: '#fff',
    minHeight: isMobile ? 'auto' : '115px',
    paddingBottom: '0px',
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
    backgroundColor: '#FF802A',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '12px',
    borderRadius: '100px',
    height: '24px',
    color: '#fff'
}));


const ChapterCard = ({ chapter }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledCard isMobile={isMobile} style={{ minHeight: '160px' }}>
            <CardContent>
                <Grid container>
                    {
                        chapter?.locked && (
                            <Grid item xs={12} style={{ marginBottom: -10 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}
                                >
                                    <LockIcon />
                                </Box>
                            </Grid>
                        )
                    }

                    <Grid item xs={isMobile ? 4 : 2.5}>
                        <LazyLoadImage src={chapter?.thumbnail} placeholderSrc={BlurryChapterPlaceHolder} alt="" width="95px" height="95px" style={{ borderRadius: '12px' }} effect="blur" />
                    </Grid>

                    <Grid item xs={isMobile ? 8 : 9.5}>
                        <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                            {chapter?.title}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                marginTop: '10px'
                            }}
                        >
                            {/* {
                                chapter.chips.map((chip, key) => {
                                    return (
                                        <StyledChip label={chip} />
                                    )
                                })
                            } */}
                            <StyledChip label={chapter?.theme} />
                        </Box>

                        <Box
                            sx={{
                                display: 'inline-flex',
                                marginTop: '16px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <IconButton style={{ padding: 0, paddingRight: 6 }}>
                                <img src={StarIcon} alt="" style={{ height: '20px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'18px'} color={"#fff"} lineHeight={'24px'}>
                                {chapter?.points}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    )
}

const ArticleCard = ({ article }) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <a href={article?.url} target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <StyledCard isMobile={isMobile} style={{ minHeight: '100%' }} sx={{ '&:hover': { cursor: 'pointer' } }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                {article.title}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography fontFamily={"Inter"} fontWeight={400} fontSize={'13px'} color={"#fff"} lineHeight={'19px'}>
                                {article.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </StyledCard>
        </a>
    )
}

const ChallangeDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const challangeData = useSelector((state) => state.guide);

    const [showAllResources, setShowAllResources] = useState(false);

    useEffect(() => {
        if (challangeData === null || challangeData === undefined) {
            navigate("/challangespage");
        }
        return () => {
            dispatch({ type: "RESET_GUIDE" });
        }
    }, [])

    // console.log(`https://www.youtube.com/embed/${challangeData?.trailer?.split('/').slice(-1)[0]}`);

    return (
        <>
            <Box
                sx={{
                    padding: '10px 20px',
                    display: 'flex',
                    justifyContent: isMobile ? "left" : "center",
                    paddingTop: 3
                }}
            >
                <Grid container direction="column" spacing={3} width={isMobile ? '100%' : '700px'}>
                    <Grid item>
                        <Box
                            onClick={() => navigate(-1)}
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': { cursor: 'pointer' }
                            }}
                        >
                            <IconButton>
                                <ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
                            </IconButton>

                            <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                                Back
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <StyledCard isMobile={isMobile}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <LazyLoadImage src={challangeData?.thumbnail} placeholderSrc={BlurryPlaceHolder} alt="" style={{ maxWidth: isMobile ? '320px' : '360px', borderRadius: '12px', maxHeight: 'auto', }} effect="blur" />
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '6px',
                                                marginTop: '6px'
                                            }}
                                        >
                                            <StyledChip label={challangeData?.guide_type} />
                                            {
                                                challangeData?.themes?.map((theme, key) => {
                                                    return (
                                                        <StyledChip key={key} label={theme} />
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'24px'}>
                                            {challangeData?.title}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography fontFamily={"Inter"} fontSize={'13px'} color={"#fff"} lineHeight={'19px'}>
                                            {challangeData?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid item>
                        <StyledCard isMobile={isMobile} style={{ minHeight: "auto" }}>
                            <CardContent>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                    {challangeData?.title}
                                </Typography>

                                <Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
                                <IconButton style={{ padding: 0, paddingRight: 6 }}>
										<img src={StarIcon} alt="" style={{ height: "20px" }} />
									</IconButton>  0 / {challangeData?.total_points}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    {
                        challangeData?.trailer && challangeData?.trailer !== "" && (
                            <Grid item>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Trailer
                                </Typography>

                                <StyledCard isMobile={isMobile}>
                                    <CardContent>
                                        <iframe
                                            width="100%"
                                            height={isMobile ? "200px" : "350px"}
                                            src={challangeData?.trailer}
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerpolicy="strict-origin-when-cross-origin"
                                            allowfullscreen>
                                        </iframe>

                                        {/* <video width="100%" height={isMobile ? "200px" : "350px"} src="https://www.youtube.com/watch?v=Vf3-s_YLgWE" controls autoPlay /> */}
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        )
                    }

                    {
                        challangeData?.chapters?.length !== 0 && (
                            <Grid item>
                                <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'22px'} color={"#fff"} lineHeight={'26px'} mb={1}>
                                    Chapters
                                </Typography>

                                <Grid container direction="column" spacing={2}>
                                    {
                                        challangeData?.chapters.map((chapter, key) => {
                                            return (
                                                <Grid item>
                                                    <ChapterCard key={key} chapter={chapter} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    }

                    <Grid item>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ChallangeDetails;