import {
	Dialog,
	useMediaQuery,
	useTheme,
	Box,
	Typography,
	Grid,
	DialogTitle,
	Modal,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import line from "../../assets/line.png";

const LikesDialog = ({ isOpen, handleClose, likesListing }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
				style={{
					display: "flex",
					alignItems: isMobile ? "flex-end" : "center",
					justifyContent: "center",
				}}
				disableAutoFocus={true}
			>
				<Box>
					<Box
						sx={{
							display: "flex",
							// justifyContent: 'center',
							flexDirection: "column",
							// alignItems: 'center',
							backgroundColor: "#000",
							// textAlign: 'center',
							width: isMobile ? "100vw" : "650px",
							minHeight: isMobile ? "300px" : "200px",
							maxHeight: isMobile ? "473px" : "393px",
							outline: "none",
						}}
					>
						{isMobile && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<img
									src={line}
									alt=""
									style={{ width: "60px", marginTop: "-20px" }}
									onClick={handleClose}
								/>
							</Box>
						)}

						<Box
							sx={{
								display: "flex",
								justifyContent: isMobile ? "left" : "space-between",
								padding: isMobile ? "" : "10px",
								paddingLeft: "15px",
							}}
						>
							<Typography
								fontFamily={"Roboto"}
								fontSize={"20px"}
								fontWeight={700}
								textTransform={"capitalize"}
								color="#fff"
							>
								Likes
							</Typography>

							{!isMobile && (
								<CloseIcon
									style={{ color: "#fff", cursor: "pointer" }}
									onClick={handleClose}
								/>
							)}
						</Box>

						<Box
							sx={{
								display: "flex",
								backgroundColor: "#000",
							}}
						>
							<Grid container>
								{likesListing?.length === 0 && (
									<Grid item xs={12}>
										<Typography
											style={{ padding: "8px", paddingLeft: "20px" }}
											fontFamily={"Roboto"}
											fontSize={"18px"}
											fontWeight={400}
											textTransform={"capitalize"}
											color="#fff"
										>
											No Likes Yet
										</Typography>
									</Grid>
								)}

								{likesListing.map((like, key) => {
									return (
										<Grid key={key} item xs={12}>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													gap: "10px",
													padding: "10px",
													paddingLeft: "20px",
												}}
											>
												{like?.user?.avatar_url ? (
													<img
														src={like?.user?.avatar_url}
														alt={""}
														style={{
															color: "#fff",
															width: "42px",
															height: "42px",
															borderRadius: "100px",
															objectFit: "cover",
														}}
													/>
												) : (
													<AccountCircleIcon
														sx={{
															color: "#fff",
															width: "50px",
															height: "50px",
														}}
													/>
												)}
												<Typography
													fontFamily={"Roboto"}
													fontSize={"20px"}
													fontWeight={400}
													textTransform={"capitalize"}
													color="#fff"
												>
													{like?.user?.first_name} {like?.user?.last_name}
												</Typography>
											</Box>
										</Grid>
									);
								})}
							</Grid>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default LikesDialog;
