import { Box } from "@mui/material";
import { Suspense, useEffect } from "react";
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import StickyFooter from './components/Footer';
import { Loading } from './components/Loader/Loader';
import Navbar from "./components/Navbar/Navbar";
import Unauthorized from './components/Unauthorized';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import Challenge from './pages/challenge/Challenge';
import Chapter from './pages/chapter/Chapter';
import Dashboard from './pages/dashboard/Dashboard';
import Landing from './pages/landing/Landing';
import EditProfile from './pages/auth/EditProfile';
import ChangePassword from './pages/auth/ChangePassword';
import StoreProvider from './store/storeProvider';
import EnrollGuides from './pages/EnrollGuides';
import GuideInfo from './pages/GuideInfo';
import Search from './pages/Search';
import Quiz from './components/Quiz';
import MissionPage from "./pages/landing/Mission";
import ChallangesPage from "./pages/landing/Challanges";
import ChallangeDetails from "./pages/landing/ChallangeDetails";
import InspirePage from "./pages/landing/Inspire";
import TeamLeaderboard from "./pages/teamLeaderboard/TeamLeaderboard";
import UserDetail from "./pages/userDetail/UserDetail";

const ContentWrapper = ({ children }) => {
  const location = useLocation();
  let version = '1';

  if (location.pathname === '/dashboard') {
    version = '2';
  }

  if (location.pathname.includes('/challenge')) {
    version = '2';
  }

  if (location.pathname.includes('/enrollGuides')) {
    version = '2';
  }

  if (location.pathname.includes('/guideInfo')) {
    version = '2';
  }

  if (location.pathname.includes('/chapter')) {
    version = '2';
  }

  if (location.pathname.includes('/search')) {
    version = '2';
  }

  if (location.pathname.includes('/editProfile')) {
    version = '4';
  }

  if (location.pathname.includes('/changePassword')) {
    version = '4';
  }

  if (location.pathname === '/') {
    version = '3';
  }

  if (location.pathname.includes('mission')) {
    version = '3';
  }

  if (location.pathname.includes('challangespage')) {
    version = '3';
  }

  if (location.pathname.includes('challangedetails')) {
    version = '3';
  }

  if (location.pathname.includes('inspire')) {
    version = '3';
  }

  if (location.pathname === '/teamLeaderboard') {
    version = '2';
  }

  if (location.pathname.includes('userDetails')) {
    version = '2';
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Navbar version={version} />
      {children}
      <StickyFooter />
    </Box>
  )
};


function Authenticate({ children }) {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return isLoggedIn ? children : <Navigate to="/login" />;
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Outlet />}>
        <Route path="" element={<Landing />} />
        <Route path="mission" element={<MissionPage />} />
        <Route path="challangespage" element={<ChallangesPage />} />
        <Route path="challangedetails" element={<ChallangeDetails />} />
        <Route path="inspire" element={<InspirePage />} />
        <Route path="login" element={<Login />} />
        <Route path="signUp" element={<SignUp />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="*" element={<Unauthorized />} />

        <Route
          path="dashboard"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <Dashboard />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="challenge/:challangeId"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <Challenge />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="chapter/:chapterId"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <Chapter />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="editProfile"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <EditProfile />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="changePassword"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <ChangePassword />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="enrollGuides"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <EnrollGuides />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="guideInfo"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <GuideInfo />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="chapter/:chapterId/quiz"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <Quiz />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="search/:searchQuery?"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <Search />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="teamLeaderboard"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <TeamLeaderboard />
              </Authenticate>
            </Suspense>
          }
        />

        <Route
          path="userDetails/:userId?"
          element={
            <Suspense fallback={<Loading fullScreen open={true} message={"Loading..."} />}>
              <Authenticate>
                <UserDetail />
              </Authenticate>
            </Suspense>
          }
        />

      </Route>
    </Routes>
  )
}

function App() {
  return (
    <StoreProvider>
      <BrowserRouter>
        <ContentWrapper>
          <AppRoutes />
        </ContentWrapper>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
