import { combineReducers } from 'redux';

import AuthReducer from './authReducer';
import { LoadingReducer } from './loadingReducer';
import PostReducer from './postReducer';
import GuideReducer from './guideReducer';
import QuizReducer from './quizReducer';
import EnrolledGuidesReducer from './enrolledGuidesReducer';
import DashboardRefreshReducer from './DashboardRefreshReducer';
import TeamReducer from './teamReducer';
import UserDetailsReducer from './userDetailsReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    appLoader: LoadingReducer,
    posts: PostReducer,
    guide: GuideReducer,
    quiz: QuizReducer,
    enrolledGuides: EnrolledGuidesReducer,
    dashboardRefresh: DashboardRefreshReducer,
    team: TeamReducer,
    userDetails: UserDetailsReducer
});

export default rootReducer;