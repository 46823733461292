import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LineChart = ({ xAxisData, yAxisData, maxYAxis }) => {
	const options = {
		chart: {
			backgroundColor: "transparent",
			height: 280,
		},
		title: {
			text: null,
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		series: [
			{
				name: "Data",
				data: [0, ...yAxisData],
				color: {
					linearGradient: {
						x1: 0,
						y1: 0,
						x2: 1,
						y2: 0,
					},
					stops: [
						[0, "rgba(0, 255, 255, 1)"],
						[1, "rgba(255, 0, 255, 1)"],
					],
				},
				dataLabels: {
					enabled: false,
				},
				lineWidth: 4,
				marker: {
					enabled: true,
					fillColor: "black",
					lineColor: "black",
					lineWidth: 2,
					radius: 4,
				},
			},
		],
		xAxis: {
			gridLineWidth: 1,
			labels: {
				formatter: function () {
					let labels = { 0: "0" };

					// eslint-disable-next-line array-callback-return
					xAxisData?.map((itm, key) => {
						labels[key + 1] = itm;
					});

					return labels[this.value] || "";
				},
				style: {
					color: "white",
				},
			},
		},
		yAxis: {
			title: {
				text: null,
			},
			gridLineWidth: 0,
			labels: {
				style: {
					color: "white",
				},
			},
			max: maxYAxis,
			min: 0,
		},
	};

	return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChart;
