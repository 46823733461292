import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	IconButton,
	Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../components/SearchComponent";

const Search = () => {
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box
			sx={{
				padding: isMobile ? "24px" : "28px",
				paddingTop: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: isMobile ? "left" : "center",
				alignItems: isMobile ? "left" : "center",
			}}
		>
			<Grid
				container
				direction="column"
				spacing={0}
				width={isMobile ? "auto" : "700px"}
			>
				<Grid item>
					<Box
						onClick={() => navigate("/dashboard")}
						sx={{
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							"&:hover": { cursor: "pointer" },
						}}
					>
						<IconButton>
							<ArrowBackIosIcon
								style={{ color: "#fff", width: "18px", height: "18px" }}
							/>
						</IconButton>

						<Typography
							fontFamily={"Inter"}
							fontWeight={700}
							fontSize={"16px"}
							color={"#fff"}
							lineHeight={"24px"}
						>
							Back
						</Typography>
					</Box>
				</Grid>
			</Grid>

			<SearchComponent />
		</Box>
	);
};

export default Search;
