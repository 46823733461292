import { toggleLoading } from './loading';
import { getChallanges, addReview, getGuides, enrollGuide, getChallangeInfo, unenrollGuide, checkIn } from '../services/challange.service';
import { NotificationToaster } from "../utils/notificationToaster";

const getChallangesAction = (param) => (dispatch) => {
    dispatch(toggleLoading(true));

    return getChallanges(param)
        .then((response) => {
            dispatch(toggleLoading(false));
            dispatch({ type: "ENROLLED_GUIDES_SUCCESS", payload: response.data.guides })
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}

const getChallangeInfoAction = (guideID) => (dispatch) => {
    dispatch(toggleLoading(true));

    return getChallangeInfo(guideID)
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            return Promise.reject(error);
        })
}

const addChallangeReviewAction = (guideId, message, rating) => (dispatch) => {
    dispatch(toggleLoading(true));

    const payload = {
        reviewable_id: guideId,
        message: message,
        rating: rating,
        reviewable_type: "Guide"
    }

    return addReview(payload)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Review Added", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error addding review", type: "error" });
            return Promise.reject(error);
        })
}

const getAllGuidesAction = () => (dispatch) => {
    dispatch(toggleLoading(true));

    return getGuides()
        .then((response) => {
            dispatch(toggleLoading(false));
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
}

const enrollGuidesAction = (ids) => (dispatch) => {
    dispatch(toggleLoading(true));

    const payload = {
        "guide_ids": ids
    }

    return enrollGuide(payload)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Enrolled in guides", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error enrolling in guides", type: "error" });
            return Promise.reject(error);
        })
}

const unenrollGuideAction = (id) => (dispatch) => {
    dispatch(toggleLoading(true));

    return unenrollGuide(id)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Guide unenrolled successfully", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error unenrolling guide", type: "error" });
            return Promise.reject(error);
        })
}

const checkInAction = (checkinId) => (dispatch) => {
    dispatch(toggleLoading(true));

    return checkIn(checkinId)
        .then((response) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Checked In", type: "success" });
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error Checking In", type: "error" });
            return Promise.reject(error);
        })
}

export {
    getChallangesAction,
    addChallangeReviewAction,
    getAllGuidesAction,
    enrollGuidesAction,
    getChallangeInfoAction,
    unenrollGuideAction,
    checkInAction
}