import { toggleLoading } from './loading';
import { getTeam } from '../services/team.service';
import { NotificationToaster } from "../utils/notificationToaster";

const getTeamAction = () => (dispatch) => {
    dispatch(toggleLoading(true));

    return getTeam()
        .then((response) => {
            dispatch({ type: "TEAM_SUCCESS", payload: response?.data?.team })
            dispatch(toggleLoading(false));
            return Promise.resolve(response);
        })
        .catch((error) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Error loading team", type: 'error' });
            return Promise.reject(error);
        })
}

export {
    getTeamAction,
}