import {
	Box,
	Card,
	CardContent,
	Chip,
	Grid,
	IconButton,
	useMediaQuery,
	useTheme,
	LinearProgress,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import BlurryPlaceHolder from "../../../assets/placeholderBlurry.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "transparent",
	color: "#fff",
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#FF802A",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const StyledChipBlack = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#000",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const CustomProgressBar = ({ value, max }) => {
	const progressPercentage = (value / max) * 100;

	return (
		<Box
			display="flex"
			alignItems="center"
			sx={{
				padding: "0px",
				borderRadius: "10px",
				width: "100%",
			}}
		>
			{/* Progress bar */}
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress
					variant="determinate"
					value={progressPercentage}
					sx={{
						height: 20,
						borderRadius: 10,
						backgroundColor: "#484848",
						"& .MuiLinearProgress-bar": {
							borderRadius: 10,
							backgroundColor: "#fff",
						},
					}}
				/>
			</Box>

			<Typography
				fontFamily={"Roboto"}
				fontWeight={300}
				fontSize={"14px"}
				color={"#fff"}
				// lineHeight={"24px"}
			>
				{`${value}/${max}`}
			</Typography>
		</Box>
	);
};

const ChallengeCardUserDetail = ({ challenge }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const handleViewGuide = (challenge) => {
		dispatch({ type: "UPDATE_GUIDE", payload: challenge });
		navigate("/guideInfo");
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				cursor: "pointer",
				marginBottom: 2,
			}}
			onClick={() => handleViewGuide(challenge)}
		>
			<Grid container spacing={2} wrap="nowrap">
				<Grid item>
					<LazyLoadImage
						src={challenge?.thumbnail}
						placeholderSrc={BlurryPlaceHolder}
						alt=""
						width="100px"
						height="100px"
						style={{ borderRadius: "12px" }}
					/>
				</Grid>

				<Grid item xs={8}>
					<Typography
						fontFamily={"Roboto"}
						fontWeight={400}
						fontSize={"20px"}
						color={"#fff"}
						lineHeight={"24px"}
					>
						{challenge?.title}
					</Typography>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "6px",
							marginTop: "6px",
						}}
					>
						<StyledChip label={challenge?.guide_type} />

						{challenge?.themes?.length !== 0 &&
							challenge?.themes?.map((theme) => {
								return <StyledChipBlack label={theme} />;
							})}
					</Box>

					<Box
						sx={{
							display: "flex",
							marginTop: "10px",
							width: "100%",
						}}
					>
						<CustomProgressBar
							value={challenge?.points_earned}
							max={challenge?.total_points}
						/>
					</Box>
				</Grid>
			</Grid>

			<KeyboardArrowRightIcon style={{ height: "80px", width: "50px" }} />
		</Box>
	);
};

export default ChallengeCardUserDetail;
