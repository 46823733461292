import { login, signUp, editUser, getUserDetails, changePassword } from '../services/auth.service';
import { NotificationToaster } from "../utils/notificationToaster";
import { toggleLoading } from './loading';

export const loginAction = (payload) => async (dispatch) => {
    dispatch({ type: "LOGIN_ATTEMPT_INIT" });
    dispatch(toggleLoading(true));
    return login(payload)
        .then((response) => {
            if (response.data?.error) {
                dispatch({ type: "LOGIN_ERROR", payload: response.data?.error });
                NotificationToaster({ message: response.data?.error, type: "error" });
                dispatch(toggleLoading(false));
                return Promise.reject(response.data?.error);
            }

            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            dispatch({ type: "LOGIN_SUCCESS", payload: response.data.user });
            // NotificationToaster({ message: "Login Successful", type: "success" });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            dispatch({ type: "LOGIN_ERROR", payload: err.message });
            NotificationToaster({ message: "Error Logging In", type: "error" });
            return Promise.reject(err);
        })
};

export const signUpAction = (formData, image) => async (dispatch) => {
    dispatch(toggleLoading(true));

    const payload = new FormData();

    payload.append("first_name", formData.firstName);
    payload.append("last_name", formData.lastName);
    payload.append("phone_number", formData.phoneNumber);
    payload.append("email", formData.emailAddress);
    payload.append("password", formData.password);
    payload.append("team", formData.teamCode);

    if (image) {
        payload.append("avatar", image);
    }

    return signUp(payload)
        .then((response) => {
            if (response.data?.error) {
                NotificationToaster({ message: response.data?.error, type: "error" });
                dispatch(toggleLoading(false));
                return Promise.reject(response.data?.error);
            }

            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            dispatch({ type: "LOGIN_SUCCESS", payload: response.data.user });
            NotificationToaster({ message: "User SignUp Successful", type: "success" });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: err[0], type: "error" });
            return Promise.reject(err);
        })
};

export const editUserAction = (formData, image, userId) => async (dispatch) => {
    dispatch(toggleLoading(true));

    const payload = new FormData();

    if (formData.firstName) {
        payload.append("first_name", formData.firstName);
      }
      
      if (formData.lastName) {
        payload.append("last_name", formData.lastName);
      }
      
      if (formData.phoneNumber) {
        payload.append("phone_number", formData.phoneNumber);
      }
      
      if (formData.emailAddress) {
        payload.append("email", formData.emailAddress);
      }
      
      if (image) {
        payload.append("avatar", image);
      }      

    return editUser(payload, userId)
        .then((response) => {
            if (response.data?.error) {
                NotificationToaster({ message: response.data?.error, type: "error" });
                dispatch(toggleLoading(false));
                return Promise.reject(response.data?.error);
            }

            // localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            dispatch({ type: "UPDATE_USER_DETAILS", payload: response.data.user });
            NotificationToaster({ message: "User Details Updated Successfully", type: "success" });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "User Details weren't Updated", type: "error" });
            return Promise.reject(err);
        })
};

export const changePasswordAction = (payload) => async (dispatch) => {
    dispatch(toggleLoading(true));

    return changePassword(payload)
        .then((response) => {
            if (response.data?.error) {
                NotificationToaster({ message: response.data?.error, type: "error" });
                dispatch(toggleLoading(false));
                return Promise.reject(response.data?.error);
            }

            NotificationToaster({ message: "Password Updated Successfully", type: "success" });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            NotificationToaster({ message: "Password could not be updated", type: "error" });
            return Promise.reject(err);
        })
};

export const getUserDetailsAction = () => async (dispatch) => {
    dispatch(toggleLoading(true));
    return getUserDetails()
        .then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data.user));
            dispatch({ type: "UPDATE_USER_DETAILS", payload: response.data.user });
            dispatch(toggleLoading(false));
            return Promise.resolve(true);
        })
        .catch((err) => {
            dispatch(toggleLoading(false));
            dispatch({ type: "LOGIN_ERROR", payload: err.message });
            return Promise.reject(err);
        })
};