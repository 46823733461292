import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const NumberCounter = ({ targetNumber }) => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		let start = 0;
		const duration = 2000;
		const increment = Math.ceil(targetNumber / (duration / 50));

		const timer = setInterval(() => {
			start += increment;
			if (start >= targetNumber) {
				setCount(targetNumber);
				clearInterval(timer);
			} else {
				setCount(start);
			}
		}, 50);

		return () => clearInterval(timer);
	}, [targetNumber]);

	return (
		<Typography
			fontFamily={"Inter"}
			fontWeight={700}
			fontSize={"20px"}
			color={"#fff"}
			lineHeight={"24px"}
		>
			{count}
		</Typography>
	);
};

export default NumberCounter;
