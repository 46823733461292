import { Box, useMediaQuery, useTheme } from "@mui/material";
import SearchComponent from "../../components/SearchComponent";

const InspirePage = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box
			sx={{
				padding: isMobile ? "24px" : "28px",
				paddingTop: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: isMobile ? "left" : "center",
				alignItems: isMobile ? "left" : "center",
			}}
		>
			<SearchComponent />
		</Box>
	);
};

export default InspirePage;
