import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	Card,
	CardContent,
	Typography,
	Chip,
	IconButton
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Feed from "../../components/Feed/Feed";
import LineChart from "../../components/LineChart";
import ChallangeCardUserDetail from "./components/ChallangeCardUserDetail";
import TableListing from "../../components/TableListing";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getChallangesAction } from "../../actions/challange";
import { getLeaderboardGraphAction } from "../../actions/leaderboard";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "130px",
}));

const StyledChip = styled(Chip)(({ isSelected }) => ({
	backgroundColor: "transparent",
	border: `1px solid ${isSelected ? "#FF802A" : "#fff"}`,
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: `${isSelected ? "#FF802A" : "#fff"}`,
	marginRight: "4px",
}));

const UserDetail = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { userId } = useParams();
	const userDetails = useSelector((state) => state.userDetails);
	const teamTarget = useSelector((state) => state?.team?.target);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const [newPost, setNewPost] = useState(false);
	const [leaderboardGraph, setLeaderboardGraph] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState("All");
	const [selectedLeaderboardFilter, setSelectedLeaderboardFilter] =
		useState("All");
	const filters = ["All", "Discovery Guides", "Action Guides", "Posts"];
	const leaderboardFilters = [
		"All",
		"Discovery Guides",
		"Action Guides",
		"Posts",
	];
	const challangesConst = {
		id: 1,
		thumbnail: null,
		title: "Reconnection",
		guide_type: "Agency",
		themes: ["Action"],
		users: ["user1", "user2", "user3", "user4"],
	};
	const teamMembers = [
		{
			id: 1,
			rank: 1,
			name: "Jane Alphabet",
			points: 908,
			avatar: "https://via.placeholder.com/150",
		},
		{
			id: 2,
			rank: 2,
			name: "Jane Beta",
			points: 895,
			avatar: "https://via.placeholder.com/150",
		},
		{
			id: 3,
			rank: 3,
			name: "Jane Beta",
			points: 895,
			avatar: "https://via.placeholder.com/150",
		},
		{
			id: 4,
			rank: 3,
			name: "Jane Beta",
			points: 895,
			avatar: "https://via.placeholder.com/150",
		},
		{
			id: 5,
			rank: 3,
			name: "Jane Beta",
			points: 895,
			avatar: "https://via.placeholder.com/150",
		},
		{
			id: 6,
			rank: 3,
			name: "Jane Beta",
			points: 895,
			avatar: "https://via.placeholder.com/150",
		},
	];

	const [activeChallanges, setActiveChallanges] = useState([]);

	const userActiveChallanges = () => {
		dispatch(getChallangesAction(userId))
			.then((res) => {
				setActiveChallanges(res.data.guides);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getTeamLeaderboardGraph = () => {
		var param;
		if (selectedLeaderboardFilter === "Discovery Guides") {
			param = "discovery";
		} else if (selectedLeaderboardFilter === "Action Guides") {
			param = "action";
		} else if (selectedLeaderboardFilter === "Posts") {
			param = "posts";
		}

		dispatch(getLeaderboardGraphAction(param))
			.then((res) => {
				setLeaderboardGraph(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		userActiveChallanges();
		getTeamLeaderboardGraph();
	}, []);

	return (
		<Box
			sx={{
				padding: "10px 20px",
				display: "flex",
				justifyContent: isMobile ? "left" : "center",
				paddingTop: 4,
			}}
		>
			<Grid
				container
				direction="column"
				spacing={3}
				width={isMobile ? "auto" : "700px"}
			>
				<Grid item>
						<Box
								onClick={() => navigate(-1)}
								sx={{
										display: 'inline-flex',
										alignItems: 'center',
										justifyContent: 'center',
										'&:hover': { cursor: 'pointer' }
								}}
						>
								<IconButton>
										<ArrowBackIosIcon style={{ color: '#fff', width: '18px', height: '18px' }} />
								</IconButton>

								<Typography fontFamily={"Inter"} fontWeight={700} fontSize={'16px'} color={"#fff"} lineHeight={'24px'}>
										Back
								</Typography>
						</Box>
				</Grid>
				<Grid item>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "5px",
						}}
					>
						{userDetails?.avatar_url ? (
							<LazyLoadImage
								src={userDetails?.avatar_url}
								alt={""}
								style={{
									color: "#fff",
									width: "42px",
									height: "42px",
									borderRadius: "100px",
									objectFit: "cover",
								}}
								effect={"blur"}
							/>
						) : (
							<AccountCircleIcon
								sx={{
									color: "#fff",
									width: "40px",
									height: "40px",
								}}
							/>
						)}

						<Typography
							fontFamily={"Inter"}
							fontWeight={900}
							fontSize={"24px"}
							color={"#fff"}
							mb={0}
							lineHeight={"29px"}
						>
							{userDetails?.first_name} {userDetails?.last_name}
						</Typography>
					</Box>
				</Grid>

				<Grid item>
					<StyledCard isMobile={isMobile}>
						<CardContent>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginBottom: 1,
										}}
									>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Xi Points
										</Typography>

										<Typography
											fontFamily={"Roboto"}
											fontWeight={300}
											fontSize={"14px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Goal = {teamTarget}
										</Typography>
									</Box>

									{filters?.map((filter, index) => {
										return (
											<StyledChip
												key={index}
												label={filter}
												isSelected={selectedFilter === filter}
												onClick={() => setSelectedFilter(filter)}
											/>
										);
									})}
								</Grid>

								<Grid item mt={1}>
									<LineChart
										yAxisData={leaderboardGraph.y_axis}
										xAxisData={leaderboardGraph.x_axis}
										maxYAxis={teamTarget}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>

				<Grid item>
					<StyledCard isMobile={isMobile}>
						<CardContent>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginBottom: 1,
										}}
									>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Active Challenges
										</Typography>

										<Typography
											fontFamily={"Roboto"}
											fontWeight={300}
											fontSize={"14px"}
											color={"#fff"}
											lineHeight={"24px"}
											style={{ cursor: "pointer" }}
										>
											View All
										</Typography>
									</Box>
								</Grid>

								<Grid item>
									{activeChallanges?.map((itm, index) => {
										return (
											<ChallangeCardUserDetail key={index} challenge={itm} />
										);
									})}
								</Grid>
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>

				<Grid item>
					<Feed
						id="feed-section"
						newPost={newPost}
						setNewPost={setNewPost}
						title={"User Feed"}
						isUserFeed={true}
						userId={userId}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default UserDetail;
