import React from "react";
import { useNavigate } from "react-router-dom";
import {
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Box,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Number1Icon from "../assets/icons/leaderboard/number1.png";
import Number2Icon from "../assets/icons/leaderboard/number2.png";
import Number3Icon from "../assets/icons/leaderboard/number3.png";
import { useDispatch } from "react-redux";

const TableListing = ({ rows }) => {
	const screenWidth = window.screen.width;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<TableContainer
			component={Paper}
			elevation={0}
			sx={{ backgroundColor: "transparent", color: "white" }}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell
							width={120}
							align="center"
							sx={{ color: "white", borderBottom: "none", padding: 0 }}
						>
							#
						</TableCell>
						<TableCell sx={{ color: "white", borderBottom: "none" }}>
							Team Member
						</TableCell>
						<TableCell
							align="right"
							sx={{ color: "white", borderBottom: "none" }}
						>
							Points
						</TableCell>
						<TableCell
							width={30}
							sx={{ color: "white", borderBottom: "none" }}
						></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((member, index) => (
						<TableRow
							key={index}
							style={{
								cursor: "pointer",
							}}
							onClick={() => {
								dispatch({
									type: "USER_DETAILS_SUCCESS",
									payload: member,
								});
								navigate(`/userDetails/${member?.id}`);
							}}
						>
							{/* Rank */}
							<TableCell
								width={120}
								align="center"
								sx={{ color: "white", borderBottom: "none", padding: 0 }}
							>
								{index === 0 && <img src={Number1Icon} alt="" height="30px" />}
								{index === 1 && <img src={Number2Icon} alt="" height="30px" />}
								{index === 2 && <img src={Number3Icon} alt="" height="30px" />}
								{index >= 3 && index + 1}
							</TableCell>

							{/* Team Member info with Avatar */}
							<TableCell
								sx={{ color: "white", borderBottom: "none", padding: 0 }}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									{screenWidth >= 390 &&
										(member?.avatar_url ? (
											<LazyLoadImage
												src={member?.avatar_url}
												alt=""
												style={{
													color: "#fff",
													width: "42px",
													height: "42px",
													borderRadius: "100px",
													objectFit: "cover",
												}}
												effect="blur"
											/>
										) : (
											<Avatar
												alt={""}
												src={member?.avatar_url}
												style={{ objectFit: "cover" }}
											/>
										))}

									<Typography variant="body1" style={{ marginLeft: 8 }}>
										{member?.first_name} {member?.last_name}
									</Typography>
								</div>
							</TableCell>

							{/* Points */}
							<TableCell
								align="right"
								sx={{ color: "white", borderBottom: "none" }}
							>
								<Typography variant="body1" fontWeight="bold">
									{member?.score ?? "0"}
								</Typography>
							</TableCell>

							<TableCell
								sx={{ color: "white", borderBottom: "none" }}
								align="right"
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<KeyboardArrowRightIcon />
								</Box>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TableListing;
